<template>
  <div class="share-vidoe">
    <div class="video-main">
      <div class="show-side">
        <div class="cate-but" @click="showCate">
          <i class="iconfont icon-shipin-copy"></i>
        </div>
      </div>
      <div class="item" v-for="item1 in videoList" :key="item1.id">
        <h3>{{ item1.name }}</h3>
        <div v-for="item2 in item1.children" :key="item2.id">
          <div class="two-title">{{ item2.name }}</div>
          <div class="videos">
            <div
              class="video"
              v-for="item3 in item2.video_list"
              :key="item3.id"
              @click="toVideoDefault(item3.id)"
              @mouseover="showMask(item3.id)"
              @mouseout="hideMask"
            >
              <div class="img-wrap">
                <div :class="mouseId === item3.id ? 'mask' : 'hide_mask'">
                  <div class="play">
                    <i class="iconfont icon-shipin-copy"></i>
                  </div>
                  <div class="foolter-data">
                    <div class="read_num">
                      <i class="iconfont icon-rentouliangrense"></i>
                      {{ item3.read_num }}
                    </div>
                    <div class="video-time">
                      <i class="iconfont icon-shijian"></i>
                      {{ item3.time }}
                    </div>
                  </div>
                </div>
                <img class="start" :src="item3.img" alt="" />
              </div>
              <div class="datas">
                <div class="name">
                  {{ item3.name }}
                </div>
                <div class="about">
                  <div class="cate">{{ item2.name }}</div>
                  <div class="date">{{ item3.date | getDateNum }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 侧边栏 -->
    <el-drawer
      title="选择视频的类型"
      :visible.sync="showTree"
      :with-header="true"
      custom-class="side-show"
    >
      <div class="side-wrap">
        <div class="cate-item">
          <div class="video-cates">
            <div
              :class="
                activeCate === null ? 'video-cate active-cate' : 'video-cate'
              "
              @click="checkCate(null)"
            >
              全部
            </div>
          </div>
        </div>
        <div class="cate-item" v-for="item1 in cateList" :key="item1.id">
          <h4>{{ item1.name }}</h4>
          <div class="video-cates">
            <div
              :class="
                activeCate === item1.id
                  ? 'video-cate active-cate'
                  : 'video-cate'
              "
              @click="checkCate(item1.id)"
            >
              全部
            </div>
            <div
              :class="
                activeCate === item2.id
                  ? 'video-cate active-cate'
                  : 'video-cate'
              "
              @click="checkCate(item2.id)"
              v-for="item2 in item1.children"
              :key="item2.id"
            >
              {{ item2.name }}
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import formatDate from "../../../utils/formatDate";

export default {
  name: "shareVideo",
  data() {
    return {
      showTree: false, // 侧边按钮
      mouseId: null, // 经过的id
      cateList: [], // 视频分类数据
      activeCate: null, // 选中分类id
      videoList: [], // 视频数据
    };
  },

  beforeMount() {
    // 判断是否需要加载
    if (!this.$store.state.videoList.cateList) {
      this.getSideData();
    }else{
      this.cateList = this.$store.state.videoList.cateList
      this.activeCate = this.$store.state.videoList.categoryId
    }
    if (!this.$store.state.videoList.videoList) {
      // 获取全部视频数据
      this.getVideoList();
    }else{
      this.videoList = this.$store.state.videoList.videoList
    }
  },
  filters: {
    getDateNum(date) {
      let newDate = formatDate.format2(date);
      return newDate;
    },
  },
  methods: {
    // 获取侧边栏数据
    async getSideData() {
      const { data: res } = await this.$http.get("video_cate/");
      if (res.code != "200") return;
      this.cateList = res.data;
      this.$store.commit("videoList/SET_CATEGORYLIST", res.data);
    },

    // 点击选中分类
    checkCate(id) {
      this.activeCate = id;
      this.getVideoList();
    },

    // 根据id获取视频数据 || 初次加载，获取全部
    async getVideoList() {
      const { data: res } = await this.$http.get("video_list", {
        params: { cate: this.activeCate },
      });
      if (res.code !== "200") return;
      this.videoList = res.data;
      this.$store.dispatch("videoList/setCategoryData", {videoList:this.videoList, cateId:this.activeCate});
    },

    showCate() {
      this.showTree = true;
    },
    // 点击视频，进入视频详情d
    toVideoDefault(id) {
      this.$router.push({
        path: "/home/video_list/videoDefault",
        query: { id },
      });
    },

    // 视频模态框显示
    showMask(id) {
      this.mouseId = id;
    },
    // 隐藏模态框
    hideMask() {
      this.mouseId = null;
    },
  },
};
</script>

<style lang='less' scoped>
// 过度效果动画
@keyframes trans {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

.h3 {
  margin-top: 30px;
  margin-bottom: 0px;
}

// 视频区
.video-main {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;

  // 侧边栏按钮
  .show-side {
    position: fixed;
    right: 20px;
    top: 46%;

    .cate-but {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #fff;
      background: #3d5aeb;
    }
  }

  // 视频列表
  .item {
    .two-title {
      font-size: 16px;
      font-weight: 700;
      color: black;
      padding-left: 20px;
      margin-top: 20px;
    }

    .videos {
      display: flex;
      flex-wrap: wrap;
      .video {
        width: 200px;
        height: 150px;
        margin: 20px 15px;

        .img-wrap {
          height: 115px;
          width: 100%;
          position: relative;
          img {
            height: 100%;
            width: 100%;
          }
          // 模态框
          .mask {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: rgba(1, 1, 1, 0.4);

            .play {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: #fff;

              i {
                font-size: 28px !important;
              }
            }
            .foolter-data {
              position: absolute;
              bottom: 0;
              height: 24px;
              line-height: 24px;
              width: 100%;
              padding: 0 5px;
              display: flex;
              justify-content: space-between;
              color: #fff;
              font-size: 10px;
              .read_num {
                i {
                  font-size: 12px !important;
                }
              }

              .video-time {
                i {
                  font-size: 12px !important;
                }
              }
            }
          }

          .start {
            background: #b1b1b1;
          }
        }

        .datas {
          display: flex;
          flex-direction: column;

          .name {
            font-size: 13px;
            height: 35px;
            width: 100%;
            padding-right: 10px;
          }
          .about {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            color: rgb(109, 109, 109);
            .cate {
            }
            .date {
            }
          }
        }
      }
    }
  }
}

// 全选按钮
.all {
  padding: 0 20px;
}

// 侧边容器
.side-wrap {
  padding: 20px;
  display: flex;
  flex-direction: column;

  .cate-item {
    .video-cates {
      display: flex;
      flex-wrap: wrap;
      padding: 5px 0;

      .video-cate {
        padding: 5px 10px;
        background: #f6f5f8;
        margin-bottom: 15px;
        margin-right: 20px;
        border-radius: 10px;
        cursor: pointer; // 显示手指
      }
      .active-cate {
        color: #3d5aeb;
        // border: 2px solid #3d5aeb;
      }
    }
  }
}

// 隐藏模态框
.hide_mask {
  display: none;
}
</style>